    import { Component, OnInit } from '@angular/core';
    import { AlertController } from '@ionic/angular';
    import { LocalStoreService } from '../services/localstore.service';
    import { KioskauthService } from '../services/kioskauth.service';
    import { ApiService } from '../services/api.service';
    import { UtilityService } from '../services/utility.service';
    import { Router, ActivatedRoute } from '@angular/router';
    import { TranslateConfigService } from '../services/translate-config.service';
    import { catchError } from 'rxjs/internal/operators/catchError';
    import { throwError } from 'rxjs/internal/observable/throwError';
    import { TranslateService } from '@ngx-translate/core';
    import { PRINT_TIMEOUT,modalTimeout,payment_timer, setIntervalTimeOut, telemetryTimeout, KIOSK_AUTO_LOGIN } from '../common/api';
    
    @Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.page.html',
    styleUrls: ['./payment-method.page.scss'],
    })
    export class PaymentMethodPage implements OnInit {
    counter;
    terminalid = "1";
    telemetry_poller:any;
    balance_poller:any;
    payment_progress = "";
    payment_terminal_status = "";
    payment_terminal_status_txt = "";
    payment_progress_txt = "";
    refund_progress_txt = "";
    modal_box= false;
    modal_type = "OK";
    payment_error : any;
    cartItems: any = [];
    totalAmount: number;
    currency = "¥";
    showDecimal= true;
    interval: any;
    timeOutApiSession = false;
    balance_blocker = false;
    telemetry_blocker = false;
    balance_shown="";
    show_balance=false;
    balance_sub : any;
    ordering_avail = true;
    inavail_mode = "REPLENISH";
    software_version = "";
    forced_transmission = "";
    time_check = "OK";
    firmware_updating = false;
    check_nega = false;
    wait_card = false;
    payment_initiated = false;
    
    temp_balance:any;
    temp_total:any;
    
    _suica_alert: any;
    suica_alert_flag = 0;
    
    ServiceID:any;
    
    constructor(
    private store:LocalStoreService,
    private router: Router,
    private kioskauthservice:KioskauthService,
    public alertController: AlertController,
    private api: ApiService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    ) { 
    this.translateConfigService.getDefaultLanguage();
    
    this.showDecimal= true;
    if(this.store.getCountry()) {
    let countryData = this.store.getCountry();
    this.currency = countryData['master_country_currency_code'];
    if(countryData['master_country_currency_decimal_places'] == 0){
    this.showDecimal= false;
    }
    }
    
    //TEST ORDER CREATION 
    /*
    let r = {"status":"success", 'deducted':this.subtotal, "balance": 2145, "card_idi": "JE30F519092724398", "paymentid": "62363" };
    this.create_kiosk_order(r);
    */
    
    }
    
    test_order(){
    //let r = "{ \"additionalData\": { \"AID\": \"A0000000031010\", \"acquirerResponseCode\": \"00 : Approved or completed successfully\", \"applicationLabel\": \"VISA CREDIT\", \"applicationPreferredName\": \"VISA CREDIT\", \"backendGiftcardIndicator\": \"false\", \"cardBin\": \"426569\", \"cardFunction\": \"Consumer\", \"cardHolderName\": \" \\/\", \"cardHolderVerificationMethodResults\": \"3F0000\", \"cardIssueNumber\": \"1\", \"cardIssuerCountryId\": \"702\", \"cardScheme\": \"visapremiumcredit\", \"cardSummary\": \"2142\", \"cardType\": \"visa\", \"expiryMonth\": \"11\", \"expiryYear\": \"2024\", \"fundingSource\": \"CREDIT\", \"giftcardIndicator\": \"false\", \"iso8601TxDate\": \"2022-02-23T12:18:47.0000000+0000\", \"issuerCountry\": \"SG\", \"merchantReference\": \"4bb0ed94-d973-4da6-b80e-5d73317195c2\", \"mid\": \"477715000203611\", \"offline\": \"false\", \"paymentMethod\": \"visa\", \"paymentMethodVariant\": \"visapremiumcredit\", \"posAmountCashbackValue\": \"0\", \"posAmountGratuityValue\": \"0\", \"posAuthAmountCurrency\": \"SGD\", \"posAuthAmountValue\": \"2\", \"posEntryMode\": \"CLESS_CHIP\", \"posOriginalAmountValue\": \"2\", \"posadditionalamounts.originalAmountCurrency\": \"SGD\", \"posadditionalamounts.originalAmountValue\": \"2\", \"pspReference\": \"MTKRK2TR2ZMH8C52\", \"tc\": \"A7AD2CFEE81FF7C0\", \"tid\": \"23583686\", \"transactionReferenceNumber\": \"MTKRK2TR2ZMH8C52\", \"transactionType\": \"GOODS_SERVICES\", \"txdate\": \"23-02-2022\", \"txtime\": \"20:18:47\" } }" ;
    var r = "{ \"additionalData\": { \"AID\": \"A0000000031010\", \"acquirerResponseCode\": \"00 : Approved or completed successfully\", \"applicationLabel\": \"VISA CREDIT\", \"applicationPreferredName\": \"VISA CREDIT\", \"backendGiftcardIndicator\": \"false\", \"cardBin\": \"426569\", \"cardFunction\": \"Consumer\", \"cardHolderName\": \" \\/\", \"cardHolderVerificationMethodResults\": \"3F0000\", \"cardIssueNumber\": \"1\", \"cardIssuerCountryId\": \"702\", \"cardScheme\": \"visapremiumcredit\", \"cardSummary\": \"2142\", \"cardType\": \"visa\", \"expiryMonth\": \"11\", \"expiryYear\": \"2024\", \"fundingSource\": \"CREDIT\", \"giftcardIndicator\": \"false\", \"iso8601TxDate\": \"2022-02-24T10:40:55.0000000+0000\", \"issuerCountry\": \"SG\", \"merchantReference\": \"879ef38c-3863-4a60-813e-8d2c9ee40385\", \"mid\": \"477715000203611\", \"offline\": \"false\", \"paymentMethod\": \"visa\", \"paymentMethodVariant\": \"visapremiumcredit\", \"posAmountCashbackValue\": \"0\", \"posAmountGratuityValue\": \"0\", \"posAuthAmountCurrency\": \"SGD\", \"posAuthAmountValue\": \"1\", \"posEntryMode\": \"CLESS_CHIP\", \"posOriginalAmountValue\": \"1\", \"posadditionalamounts.originalAmountCurrency\": \"SGD\", \"posadditionalamounts.originalAmountValue\": \"1\", \"pspReference\": \"X8VST533LDTG2C52\", \"tc\": \"C93391A9C1586A4D\", \"tid\": \"23583809\", \"transactionReferenceNumber\": \"X8VST533LDTG2C52\", \"transactionType\": \"GOODS_SERVICES\", \"txdate\": \"24-02-2022\", \"txtime\": \"18:40:55\" } }";
    
    r = this.stripslashes(r); 
    r = JSON.parse(r);
    
    this.create_kiosk_order(r); 
    }
    
    time_left_counter : any;
    time_left = 0;
    time_stop = false;
    show_cancel_button = false;
    mid_cancel = false;
    
    ngOnInit() {
    
    this.counter = 0;
    
    this.time_stop = false;
    
    this.time_left = payment_timer;
    console.log('calling timer constant')
    
    this.time_left_counter = setInterval(()=>{
    this.time_left -= 1;
    if (this.time_left == 0) {
    clearInterval(this.time_left_counter);
    this.time_out();
    
    this.alertController.dismiss();
    
    return false;
    } 
    }, setIntervalTimeOut);
    }
    
    ionViewWillEnter() {
    this.isRestaurantUnderMaintainance();
    /*
    setTimeout(() => {this.show_cancel_button=true;}, 3000);
    
    setTimeout(() => {
    this.cancelOrder();
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart");
    this.router.navigate(['/scan']);
    }, 15000);
    */ 
    }
    
    ionViewDidEnter(){
    this.counter = 0;
    if (localStorage.getItem('terminalid') == null) localStorage.setItem('terminalid','1');
    this.terminalid = localStorage.getItem('terminalid') ;
    }
    
    
    isRestaurantUnderMaintainance(){
    let param = {
    master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
    }
    this.kioskauthservice.apiReq('post', 'masterrestaurant/getbyuuid', param)
    .subscribe((res) => {
    if (res.statusCode == 200) {
    console.log(res.aaData['master_restaurant_status'])
    if(res.aaData['master_restaurant_status'] == 'M_ON'){
    this.router.navigate([`home`]);
    } else {
    this.loadAllData();
    }
    } else {
    this.loadAllData();
    }
    }, err => {
    
    });
    }
    
    pay_sub: any;
    
    loadAllData(){
    const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    if(items.length == 0){
    this.router.navigate([`home`]);
    return false;
    }
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
    this.totalAmount = v[0].total + +this.totalAmount;
    Object.entries(v).forEach(([key, value]) => {
    this.cartItems.push(value);
    })
    });
    
    
    
    
    // PAYMENT REQUIRED (CREDIT CARD) - Adyen in Bytestation
    let terminalid = this.store.get('terminalid');
    //let ServiceID = this.rand(10000000, 9999999);
    this.ServiceID = this.generate_rand_num();
    
    let ORDER_UUID = this.store.get('orderId');
    console.log("before wait_for_payment", new Date());
    this.pay_sub = this.api.wait_for_payment(this.ServiceID, this.totalAmount, terminalid).subscribe(
    r => {
    console.log("after wait_for_payment", new Date());
    console.log(r);
    if (r === '"Cancelled"' || r === '"Failure"' || r === 'Cancelled' || r === 'Failure' ) {
    console.log("payment unsuccessful...");
    // return to main
    //this.cancelOrder();
    //setTimeout(() => { this.router.navigate([`/autologin/1`]); }, PRINT_TIMEOUT); 
    setTimeout(() => { 
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart");
    this.alertController.dismiss();
    this.router.navigateByUrl('/started');
    }, PRINT_TIMEOUT);
    
    }
    else{
    console.log("payment successful!"); 
    console.log("before insert_order", new Date());
    
    console.log("Raw Payment Response ",r); 
    r = this.stripslashes(r); 
    console.log("Payment Response Slashed",r); 
    
    
    r= r.replace('"{', '{');
    r= r.replace('}"', '}');
    
    console.log("Payment Response post enclising quotes removal ",r); 
    
    r = JSON.parse(r);
    console.log("Payment Response PARSED AS JSON",r); 
    
    this.create_kiosk_order(r);
    console.log("after insert_order", new Date()); 
    
    //setTimeout(() => { this.router.navigate([`/autologin/1`]); }, PRINT_TIMEOUT); 
    
    setTimeout(() => { 
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart");
    this.alertController.dismiss();
    this.router.navigateByUrl('/started');
    }, PRINT_TIMEOUT);
    
    
    } 
    }
    );
    
    
    
    
    this.balance_poller = setInterval(
    ()=>{
    //this.check_balance();
    }
    , telemetryTimeout);
    }
    
    stripslashes (str) {
    // discuss at: https://locutus.io/php/stripslashes/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Ates Goral (https://magnetiq.com)
    // improved by: marrtins
    // improved by: rezna
    // fixed by: Mick@el
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Brett Zamir (https://brett-zamir.me)
    // input by: Rick Waldron
    // input by: Brant Messenger (https://www.brantmessenger.com/)
    // reimplemented by: Brett Zamir (https://brett-zamir.me)
    // example 1: stripslashes('Kevin\'s code')
    // returns 1: "Kevin's code"
    // example 2: stripslashes('Kevin\\\'s code')
    // returns 2: "Kevin\'s code"
    return (str + '')
    .replace(/\\(.?)/g, function (s, n1) {
    switch (n1) {
    case '\\':
    return '\\'
    case '0':
    return '\u0000'
    case '':
    return ''
    default:
    return n1
    }
    })
    }
    
    generate_rand_num(){
    var rand:any; 
    rand = Math.random();
    rand = rand*10000000000;
    rand = Math.floor(rand);
    rand = ''+rand;
    rand = rand.substr(0,8);
    return rand;
    }
    
    rand (min, max) {
    // discuss at: https://locutus.io/php/rand/
    // original by: Leslie Hoare
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // note 1: See the commented out code below for a version which
    // note 1: will work with our experimental (though probably unnecessary)
    // note 1: srand() function)
    // example 1: rand(1, 1)
    // returns 1: 1
    const argc = arguments.length
    if (argc === 0) {
    min = 0
    max = 2147483647
    } else if (argc === 1) {
    throw new Error('Warning: rand() expects exactly 2 parameters, 1 given')
    }
    return Math.floor(Math.random() * (max - min + 1)) + min
    }
    
    handleError2(error) {
    // handleError2 = async(error) => {
    let errorMessage = '';
    
    
    if (error.error instanceof ErrorEvent) {
    // client-side error
    errorMessage = "{E1 : "+error.message+"} ";
    console.log("E1: 決裁が完了できませんでした（タイムアウト）"); 
    this.payment_error = "CUSTOM ERROR 0x01: "+error.message;
    
    } else {
    // server-side error
    errorMessage = "{E2 : "+error.message+"} ";
    console.log("E2: 決裁が完了できませんでした（タイムアウト）"); 
    
    this.payment_error = "CUSTOM ERROR 0x02: "+error.message;
    
    } 
    // alert(" 決裁が完了できませんでした（タイムアウト）"); 
    
    return throwError(alert);
    
    }
    
    attempted: any;
    deducted:any;
    balance: any;
    card_idi:any;
    ic_handling_number:any;
    
    create_kiosk_order(r) {
    
    console.log("ORDER CREATE ");
    console.log("r = ",r);
    //DECODED Resposne
    //let additionalData = this.stripslashes(r);
    //let additionalData = { "additionalData": { "AID": "A0000000031010", "acquirerResponseCode": "00 : Approved or completed successfully", "applicationLabel": "VISA CREDIT", "applicationPreferredName": "VISA CREDIT", "backendGiftcardIndicator": "false", "cardBin": "426569", "cardFunction": "Consumer", "cardHolderName": " \/", "cardHolderVerificationMethodResults": "3F0000", "cardIssueNumber": "1", "cardIssuerCountryId": "702", "cardScheme": "visapremiumcredit", "cardSummary": "2142", "cardType": "visa", "expiryMonth": "11", "expiryYear": "2024", "fundingSource": "CREDIT", "giftcardIndicator": "false", "iso8601TxDate": "2022-02-23T12:18:47.0000000 0000", "issuerCountry": "SG", "merchantReference": "4bb0ed94-d973-4da6-b80e-5d73317195c2", "mid": "477715000203611", "offline": "false", "paymentMethod": "visa", "paymentMethodVariant": "visapremiumcredit", "posAmountCashbackValue": "0", "posAmountGratuityValue": "0", "posAuthAmountCurrency": "SGD", "posAuthAmountValue": "2", "posEntryMode": "CLESS_CHIP", "posOriginalAmountValue": "2", "posadditionalamounts.originalAmountCurrency": "SGD", "posadditionalamounts.originalAmountValue": "2", "pspReference": "MTKRK2TR2ZMH8C52", "tc": "A7AD2CFEE81FF7C0", "tid": "23583686", "transactionReferenceNumber": "MTKRK2TR2ZMH8C52", "transactionType": "GOODS_SERVICES", "txdate": "23-02-2022", "txtime": "20:18:47" } } ;
    //r = additionalData;
    //r = JSON.parse(r);
    console.log("additionalData = ",r);
    
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    
    this.cartItems=[];
    Object.entries(cartDetail).forEach(([k, v]) => {
    this.totalAmount = v[0].total + +this.totalAmount;
    Object.entries(v).forEach(([key, value]) => {
    this.cartItems.push(value);
    })
    });
    
    let order_type = "TAKE_AWAY";
    if(this.store.get("order_type") == "Dine In"){
    order_type = "DINE_IN";
    }
    let totalCartAmount = this.totalAmount;
    let cartItemCount = this.store.get("cartCartItemCount")
    //let cart = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    let user = this.store.getUser(); 
    
    
    this.attempted = this.totalAmount;
    console.log("after wait_for_payment", new Date());
    console.log(r);
    
    
    this.time_stop = true;
    clearInterval(this.time_left_counter);
    console.log("payment successful!"); 
    this.deducted = this.totalAmount;
    this.balance = r.additionalData.posOriginalAmountValue;
    var proc_card_idi = this.card_idi = "**** **** **** "+r.additionalData.cardSummary;
    this.ic_handling_number = r.additionalData.mid;
    console.log("before insert_order", new Date());
    let paymentid = "436346";
    var mode = (window.localStorage.getItem('tax') == '8') ? 'TAKEAWAY' : 'DINEIN';
    //this.insert_order(m_orders,customername,paymentid, this.subtotal, mode); 
    
    
    
    let data = {
    order_info_uuid: this.store.get('orderId'),
    order_info_customer: user.user_information_uuid,
    master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    order_info_total_price: totalCartAmount,
    order_info_total_order_quantity: cartItemCount,
    order_info_total_discount: 0,
    available_redeemed_amount: 0,
    points_redeemed_amount: 0,
    master_coupon_uuid: null,
    master_subscription_uuid: null,
    subscription_mapping_uuid: null,
    used_subscription_quantity: 0,
    order_info_gst: 0, //Number(this.gst.toFixed(2)),
    // order_info_service_tax [OPTIONAL]
    order_info_grand_total: totalCartAmount,
    order_info_delivery_date: "",
    master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
    orders: this.cartItems,
    order_info_table_number: null,
    order_info_car_plate_number: null,
    order_info_cutlery: 'FALSE',
    order_info_type: order_type,//this.store.get("order_type"),
    order_info_dinningmode: null,
    order_info_dinningtime: null,
    delivery_address: null,
    is_sap_order: "NO",
    country_uuid: this.store.getCountry().master_country_uuid,
    request_from: "SCB",
    deducted: this.deducted,
    balance: this.balance,
    
    suica_payment_details: {"deducted": this.deducted, "balance": this.balance, "card_idi": proc_card_idi, "paymentid": r.additionalData.mid, "RESPONSE":r }
    }
    
    
    this.kioskauthservice.apiReq('post', 'orderinfo/createkioskorder', data).subscribe((res) => {
    if (res.statusCode == 200) {
    
    if(res.statusCode == 200) {
    this.store.remove("orderId");
    this.store.remove("totalAmount");
    this.store.remove("totalCartAmount");
    this.store.setUserCart([]); 
    this.store.remove("selectedItemsInCart");
    
    this.router.navigateByUrl('/settlement-complete?id='+res.aaData['order_info_uuid']+"&c="+proc_card_idi+"&b="+ r['balance']) ;
    return false;
    } 
    
    
    console.log(res.aaData['master_restaurant_status'])
    if(res.aaData['master_restaurant_status'] == 'M_ON'){
    this.router.navigate([`home`]);
    } else {
    
    }
    } else {
    
    }
    }, err => {
    
    })
    
    
    }
    
    
    async presentAlertMultipleButtons() {
    const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    header: 'Alert',
    subHeader: 'Subtitle',
    message: 'This is an alert message.',
    buttons: ['Cancel', 'Open Modal', 'Delete']
    });
    setTimeout(() => {this.alertController.dismiss();}, modalTimeout);
    await alert.present();
    }
    
    
    wait_for_suica_paymnet: any;
    
    saveOrderDetailsold(){
    //this.store.setUserCart([]);
    let order_type = "TAKE_AWAY";
    if(this.store.get("order_type") == "Dine In"){
    order_type = "DINE_IN";
    }
    let totalCartAmount = this.totalAmount;
    let cartItemCount = this.store.get("cartCartItemCount")
    //let cart = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    let user = this.store.getUser();
    let data = {
    order_info_uuid: this.store.get('orderId'),
    order_info_customer: user.user_information_uuid,
    master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    order_info_total_price: totalCartAmount,
    order_info_total_order_quantity: cartItemCount,
    order_info_total_discount: 0,
    available_redeemed_amount: 0,
    points_redeemed_amount: 0,
    master_coupon_uuid: null,
    master_subscription_uuid: null,
    subscription_mapping_uuid: null,
    used_subscription_quantity: 0,
    order_info_gst: 0, //Number(this.gst.toFixed(2)),
    // order_info_service_tax [OPTIONAL]
    order_info_grand_total: totalCartAmount,
    order_info_delivery_date: "",
    master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
    orders: this.cartItems,
    order_info_table_number: null,
    order_info_car_plate_number: null,
    order_info_cutlery: 'FALSE',
    order_info_type: order_type,//this.store.get("order_type"),
    order_info_dinningmode: null,
    order_info_dinningtime: null,
    delivery_address: null,
    is_sap_order: "NO",
    country_uuid: this.store.getCountry().master_country_uuid,
    request_from: "KIOSK"
    };
    
    }
    
    async timeout_error() {
    let telemetry_title = " 決裁が完了できませんでした";
    let telemetry_message = "係員にお問い合わせください。";
    this.translate.get('ERRORS').subscribe((data: any) => {
    // telemetry_title = data.Unable_to_connect;
    // telemetry_message = data.Please_contact_the_staff;
    });
    
    const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    header: telemetry_title,
    message: '<p>交通系支払金額</p><span class="black">'+this.currency+''+this.totalAmount+'</span><p>係員にお問い合わせください。係員不在の場合は、機器左側にある連絡先までご連絡ください</p></span>',
    
    //message: "<p> 係員にお問い合わせください。 <br /> 係員不在の場合は、 <br /> 機器左側にある連絡先までご連絡ください。 </p>",
    buttons: [ 
    
    // {
    // text: '',
    // cssClass: 'cross',
    // role: 'cancel',
    
    // }
    ]
    });
    setTimeout(() => {this.alertController.dismiss();}, modalTimeout);
    
    setTimeout(() => {this.router.navigateByUrl('/started');}, 2000);
    
    await alert.present();
    }
    
    async incomplete_payment() {
    // let telemetry_title = " もう一度、カードタッチして下さい";
    let telemetry_message = "";
    this.translate.get('ERRORS').subscribe((data: any) => {
    // telemetry_title = data.Unable_to_connect;
    // telemetry_message = data.Please_contact_the_staff;
    });
    
    const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    // header: telemetry_title,
    message: "<p>もう一度、カードタッチして下さい </p>",
    buttons: [ 
    
    // {
    // text: '',
    // cssClass: 'cross',
    // role: 'cancel',
    
    // }
    ]
    });
    
    setTimeout(() => {this.alertController.dismiss();}, 3000);
    await alert.present();
    }
    
    
    ionViewDidLeave(){
    clearInterval(this.time_left_counter);
    clearInterval(this.balance_poller);
    clearInterval(this.telemetry_poller);
    //clearInterval(this.telemetry_poller);tel
    }
    
    cancelOrder(){
    
    
    
    this.mid_cancel = true;
    clearInterval(this.time_left_counter);
    
    let terminalid = this.store.get('terminalid'); 
    
    this.api.wait_for_abort_payment(this.ServiceID,terminalid)
    .subscribe(res => { 
    console.log(" Adyen PAYMENT ABORT "); 
    console.log("payment canceled and redirected")
    
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart");
    this.router.navigate(['/scan']);
    
    },
    (err) => { 

    this.Abort_payment();
    
    
    }
    
    )
    
    
    } 
    
    Abort_payment(){
    
    let cart_details =JSON.parse( localStorage.getItem("30b1a711dec4bd62b2b9ac89f7bfb1da"))
    
    let store_cart_details={
    'cart_id':cart_details,
    }
    let newpram={
    'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
    new_value:store_cart_details
    }
    
    this.kioskauthservice.apiReq('post','orderinfo/updateauditlog',newpram)
    .subscribe((res) => {
    this.technical_error();
    
    }); 
    }
    
    reset_storage(){
    let language = this.store.get('language');
    let user = this.store.get('user');
    let user_info = this.store.get('user_info');
    let terminalid = this.store.get('terminalid');
    let user_information_uuid = this.store.get('user_information_uuid');
    let store_info = this.store.get('store_info');
    let selectedCountry = this.store.get('selectedCountry');
    let restaurant = this.store.get('restaurant');
    
    this.store.clear();
    
    
    this.store.set('language', language);
    this.store.set('user', user);
    this.store.set('user_info', user_info);
    this.store.set('terminalid', terminalid);
    this.store.set('user_information_uuid', user_information_uuid);
    this.store.set('store_info', store_info);
    this.store.set('selectedCountry', selectedCountry);
    this.store.set('restaurant', restaurant); 
    
    
    console.log(" Storage CLEARED")
    }
    
    
    async orderAlert() {
    const alert = await this.alertController.create({
    backdropDismiss: false,
    cssClass: 'my-custom-class',
    mode: 'md',
    header: '決裁が完了できませんでした（タイムアウト）',//'Settlement could not be completed',
    message: '<p><strong>(タイムアウト)</strong>交通系支払</p><span class="green">'+this.currency+' '+this.totalAmount+'</span>',
    buttons: [ 
    {
    text: '最初から',//'Back',
    handler: () => {
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart");
    this.alertController.dismiss();
    this.router.navigateByUrl('/home');
    }
    },
    // {
    // text: '',
    // cssClass: 'cross',
    // handler: () => {
    // this.store.setUserCart([]); 
    // this.store.remove("formFields");
    // this.store.remove("cart");
    // this.alertController.dismiss();
    // this.router.navigateByUrl('/home');
    // }
    // }
    ]
    });
    setTimeout(() => {this.alertController.dismiss();}, modalTimeout);
    await alert.present();
    }
    
    
    
    
    async suicaPaymentTapAgainAlert(paymentAmount){
    // this.utilityService.hide_suica_alert(); 
    
    const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    header: 'もう一度、タッチしてください',//'Please Tap Again',
    message: '<p>支払い</p><span class="green">'+this.currency+' '+paymentAmount+'</span>',
    buttons: [ 
    {
    text: '最初から',//'Back',
    handler: () => {
    this.alertController.dismiss();
    
    //this.router.navigateByUrl('/payment-method');
    }
    },
    // {
    // text: '',
    // cssClass: 'cross',
    // handler: () => {
    // this.alertController.dismiss();
    // this.saveOrderDetails();
    // //this.router.navigateByUrl('/payment-method');
    // }
    // }
    ]
    });
    setTimeout(() => {this.alertController.dismiss();}, modalTimeout);
    await alert.present();
    }
    
    saveOrderDetails(){
    //this.store.setUserCart([]);
    let order_type = "TAKE_AWAY";
    if(this.store.get("order_type") == "Dine In"){
    order_type = "DINE_IN";
    }
    let totalCartAmount = this.totalAmount;
    let cartItemCount = this.store.get("cartCartItemCount")
    //let cart = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
    let user = this.store.getUser();
    let data = {
    order_info_uuid: this.store.get('orderId'),
    order_info_customer: user.user_information_uuid,
    master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    order_info_total_price: totalCartAmount,
    order_info_total_order_quantity: cartItemCount,
    order_info_total_discount: 0,
    available_redeemed_amount: 0,
    points_redeemed_amount: 0,
    master_coupon_uuid: null,
    master_subscription_uuid: null,
    subscription_mapping_uuid: null,
    used_subscription_quantity: 0,
    order_info_gst: 0, //Number(this.gst.toFixed(2)),
    // order_info_service_tax [OPTIONAL]
    order_info_grand_total: totalCartAmount,
    order_info_delivery_date: "",
    master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
    orders: this.cartItems,
    order_info_table_number: null,
    order_info_car_plate_number: null,
    order_info_cutlery: 'FALSE',
    order_info_type: order_type,//this.store.get("order_type"),
    order_info_dinningmode: null,
    order_info_dinningtime: null,
    delivery_address: null,
    is_sap_order: "NO",
    country_uuid: this.store.getCountry().master_country_uuid,
    request_from: "SCB",
    simulation:1
    };
    
    this.kioskauthservice.apiReq('post','orderinfo/create',data).subscribe(response => {
    
    let _balance= Math.random()*100;
    let paymentData = [];
    paymentData['deducted'] = totalCartAmount;
    paymentData['balance'] = _balance
    paymentData['card_idi'] = "****************"+Math.random()*1000;
    paymentData['paymentid'] = "PPP_"+Math.random();
    paymentData['payment_response'] = {"type":"simulation"};
    
    this.store.remove("orderId");
    this.store.remove("totalAmount");
    this.store.remove("totalCartAmount");
    this.store.setCart([]); 
    this.store.remove("selectedItemsInCart");
    //this.router.navigate([`settlement-complete`]);
    
    let payment_response_sample1 = {"RES": {"status": "Success", "balance": _balance, "card_idi": "JE30F519092724398", "deducted": 1, "record_id": "DY21785FLHAZBU80", "prev_balance": 675, "IC_handling_number": "5499"} };
    
    //update balance
    let suicabalance = {
    deducted: totalCartAmount,
    balance:_balance,
    //payment_response: paymentData,
    payment_response: payment_response_sample1,
    order_info_uuid: response?.aaData['order_info_uuid']
    }
    
    this.kioskauthservice.apiReq('post', 'orderinfo/updatesuicabalance' , suicabalance).subscribe(res =>{
    console.log("UPDATE BAL 1111: ",res);
    this.router.navigateByUrl('/settlement-complete?id='+response.aaData['order_info_uuid']+"&c="+paymentData['card_idi']+"&b="+ paymentData['balance']) ;
    return false;
    
    });
    
    //this.router.navigateByUrl('/settlement-complete?id='+response.aaData['order_info_uuid']);
    return false;
    // if(response.statusCode == 200) {
    // this.store.set('orderId',response.aaData['order_info_uuid']);
    // this.store.remove("totalAmount");
    // this.store.remove("totalCartAmount");
    // this.store.setCart([]); 
    // this.store.remove("selectedItemsInCart"); 
    // this.router.navigateByUrl('/settlement-complete?id='+response.aaData['order_info_uuid']);
    
    // let res:any;
    
    // let data = {
    // order_info_uuid: response.aaData['order_info_uuid'],
    // restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    // payment_response: res
    // }
    
    // this.kioskauthservice.apiReq('post','orderinfo/updatekioskorderstatus',data).subscribe(res => {
    // // if(res.statusCode == 200) {
    // this.store.remove("orderId");
    // this.store.remove("totalAmount");
    // this.store.remove("totalCartAmount");
    // this.store.setCart([]); 
    // this.store.remove("selectedItemsInCart");
    // this.router.navigate([`settlement-complete`]);
    // this.router.navigateByUrl('/settlement-complete?id='+response.aaData['order_info_uuid']);
    // return false;
    // //} 
    // });
    /* 
    console.log("DEBUG 1",totalCartAmount, this.terminalid);
    
    this.api.wait_for_suica_payment(totalCartAmount, this.terminalid).subscribe(res => {
    clearInterval(this.interval);
    this.timeOutApiSession = true;
    if(res['status'] == 'Success') {
    let paymentData = [];
    paymentData['deducted'] = res['deducted'];
    paymentData['balance'] = res['balance'];
    paymentData['card_idi'] = res['card_idi'];
    paymentData['paymentid'] = res['record_id'];
    paymentData['payment_response'] = res;
    let data = {
    order_info_uuid: response.aaData['order_info_uuid'],
    restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    payment_response: paymentData
    }
    this.kioskauthservice.apiReq('post','orderinfo/updatekioskorderstatus',data).subscribe(res => {
    if(res.statusCode == 200) {
    this.store.remove("orderId");
    this.store.remove("totalAmount");
    this.store.remove("totalCartAmount");
    this.store.setCart([]); 
    this.store.remove("selectedItemsInCart");
    this.router.navigate([`settlement-complete`]);
    this.router.navigateByUrl('/settlement-complete?id='+response.aaData['order_info_uuid']);
    return false;
    } 
    });
    } else if ((response['status'] === 'Already' ) ){
    this.suicaPaymentAlreadyStatusAlert()
    } else {
    this.suicaPaymentTapAgainAlert(totalCartAmount)
    }
    })
    */
    //}
    })
    
    }
    
    message:any;
    
    //payment failed
    async suicaPaymentAlreadyStatusAlert() {
    
    this.message = "Please tap again";
    if(this.counter==2){
    this.message = '';
    }
    const alert = await this.alertController.create({
    
    cssClass: 'my-custom-class',
    mode: 'md',
    
    header: 'Payment Failed',
    message: '<h1>'+this.message+'</h1>',
    buttons: [
    {
    
    text: 'Done',
    handler: () => {
    
    this.alertController.dismiss();
    this.router.navigateByUrl('/payment-method');
    this.time_left = payment_timer;
    this.counter ++;
    if (this.counter != 3){
    this.make_payment();
    }

    if (this.counter == 3) {
    text : ''
    this.store.setUserCart([]);
    this.store.remove("formFields");
    this.store.remove("cart");
    this.alertController.dismiss();
    
    this.router.navigateByUrl('/scan');
    }
    }
    
    },
    
    {
    text: '',
    cssClass: 'cross',
    handler: () => {
    this.time_left = payment_timer;
    this.alertController.dismiss();
    this.router.navigateByUrl('/payment-method');
    this.counter++;
     if (this.counter != 3){
    this.make_payment();
    }
    
    if (this.counter == 3) {
    this.store.setUserCart([]);
    this.store.remove("formFields");
    this.store.remove("cart");
    this.alertController.dismiss();
    
    this.router.navigateByUrl('/scan');
    }
    }
    }
    ]
    });
    // setTimeout(() => {this.alertController.dismiss();}, modalTimeout);
    await alert.present();
    }
    
    go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
    }
    
    make_payment(){
    this.pay_sub.unsubscribe();
    
    this.loadAllData()
    
    }
    
    async technical_error() {
    let telemetry_title = " Sorry! "
    
    const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    header: telemetry_title,
    
    message: "<h1>There is Some technical Error, <br />Please try after sometimes</h1>",
    buttons: [ 
    
    {
    text: 'Done',
    
    handler: () => {
    
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart"); 
    this.router.navigateByUrl('/scan');
    }
    },
    
    {
    text: '',
    cssClass: 'cross',
    handler: () => {
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart"); 
    this.router.navigateByUrl('/scan');
    }
    
    
    
    }
    ]
    });
    
    // setTimeout(() => {this.alertController.dismiss();}, 6000);
    await alert.present();
    }
    
    async time_out() {
    let telemetry_title = " Error "
    
    const alert = await this.alertController.create({
    cssClass: 'my-custom-class',
    mode: 'md',
    message: "<h1>Time out</h1>",
    buttons: [ 
    
    {
    text: 'Done',
    
    handler: () => {
    
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart"); 
    this.store.hardlogout()
    location.href=KIOSK_AUTO_LOGIN;
    }
    },
    
    {
    text: '',
    cssClass: 'cross',
    handler: () => {
    this.store.setUserCart([]); 
    this.store.remove("formFields");
    this.store.remove("cart"); 
    this.store.hardlogout()
    location.href=KIOSK_AUTO_LOGIN;
    
    }
    
    
    
    }
    ]
    });
    
    setTimeout(() => {this.alertController.dismiss();}, 6000);
    setTimeout(() => {
      this.store.setUserCart([]); 
      this.store.remove("formFields");
      this.store.remove("cart");          
      this.store.hardlogout()
      location.href=KIOSK_AUTO_LOGIN;
    }, 6000);
    await alert.present();
    }
    
    }