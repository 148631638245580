
import { Component } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { KIOSK_AUTO_LOGIN, modalTimeout, payment_timer, PRINT_TIMEOUT, setIntervalTimeOut, telemetryTimeout, timer_time } from '../common/api';
import { TranslateService } from '@ngx-translate/core';
import { CartItemDetailPage } from '../cart-item-detail/cart-item-detail.page';
import { PageLoaderService } from '../services/page-loader.service';
import { environment } from 'src/environments/environment';
import { NameModalPage } from '../name-modal/name-modal.page';
import { AuditLogService } from '../services/audit-log.service';
import { KioskapiService } from '../services/kioskapi.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  menu_item: boolean;
  telemetry_blocker = false;
  forced_transmission = "";
  payment_progress_txt = "";
  payment_progress = "";
  payment_terminal_status = "";
  payment_terminal_status_txt = "";
  firmware_updating = false;
  software_version = "";
  ordering_avail = true;
  time_check = "OK";
  payment_initiated = false;
  check_nega = false;
  wait_card = false;
  telemetry_poller: any;
  maintain_timer: any;
  is_maintaince_mode_on = true;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  counter;
  terminalid = "1";
  // cartItems: any = [];
  restaurant_mode;
  balance_poller: any;
  balance;
  refund_progress_txt = "";
  modal_box = false;
  modal_type = "OK";
  payment_error: any;
  proc_card_idi;
  cartItems_create_order: any = [];
  interval: any;
  timeOutApiSession = false;
  balance_blocker = false;
  balance_shown = "";
  show_balance = false;
  balance_sub: any;
  inavail_mode = "REPLENISH";
  terminalResponse: any;
  hide_cancel_button: any;
  payprog_lock: any;
  subtype: any;
  deducted: any;
  attempted: any;
  temp_balance: any;
  temp_total: any;
  _suica_alert: any;
  suica_alert_flag = 0;
  app_settings;
  ServiceID: any;
  is_restaurant_closed_status = "NO";
  time_left_counter: any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  restaurant_status_interval: any;
  obj_restaurantDetails;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;

  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private api: ApiService,
    public alertController: AlertController,
    private translate: TranslateService,
    private pageLoaderService: PageLoaderService,
    public auditlogServices: AuditLogService,
    private kiosk_apiService : KioskapiService
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
    this.check_auth();
    // this.is_restaurant_closed();
    this.getAppSettings();
  }

  ngOnInit() {
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.check_auth();
    // clearInterval();
    this.store.remove("totalCartAmount");
    this.store.setCart([]);
  }

  obj_is_restaurant_closed;
  is_restaurant_closed() {
    this.obj_is_restaurant_closed = this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
          // this.pageLoaderService.pageLoaderPersistent(false)
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
            // localStorage.removeItem('restaurant');
            // this.store.setRestaurant(res.aaData);
          }
        },
      );
  }

  async orderAlert() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      mode: 'md',
      header: '決裁が完了できませんでした（タイムアウト）',//'Settlement could not be completed',
      // message: '<p><strong>(タイムアウト)</strong>交通系支払</p><span class="green">'+this.currency+' '+this.totalAmount+'</span>',
      buttons: [
        {
          text: '最初から',//'Back',
          handler: () => {
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            this.router.navigateByUrl('/started');
          }
        },
        // {
        // text: '',
        // cssClass: 'cross',
        // handler: () => {
        // this.store.setUserCart([]); 
        // this.store.remove("formFields");
        // this.store.remove("cart");
        // this.alertController.dismiss();
        // this.router.navigateByUrl('/started');
        // }
        // }
      ]
    });
    // setTimeout(() => { this.alertController.dismiss(); }, 3000);
    // await alert.present();
  }

  ionViewWillEnter() {
    this.getMenuItems();
    this.menu_item = false;
    // this.reset_time();
    this.app_setting();
    this.telemetry_poller = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);

    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 9000)

    // this.clock_Timer();

    // this.getRestaurantDetails();

    this.orderType = this.store.get('order_type');
    this.check_auth();

    this.getCartItems();
    if (this.cartItemCount == 0) {
      // clearInterval(this.time_left_counter);
    }
    this.maintain_timer = setInterval(
      () => {

        if (this.shop.master_restaurant_status == 'M_ON') {
          this.is_maintaince_mode_on = true;
        }
        else {
          this.is_maintaince_mode_on = false;
        }

        // this.is_maintaince_mode_on =false;
      }
      , telemetryTimeout);
  }

  getMenuItems() {
    this.kiosk_apiService.menu_items().subscribe((response) => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          if (response.final_response) {
            this.menu_item = true;
            this.menuItems = response.final_response;
          } else {
            this.menu_item = true;
            this.menuItems = response.aaData;
          }
        }

      },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
        }
      );
  }

  check_auth() {
    if (!this.kioskauthservice.is_logged_in()) {
      this.kioskauthservice.logout();
    }
  }

  async redirectOnDetailPage(item) {
    this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.redirectOnItemDetailPage(item);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
      if (item.master_menu_item_is_combo == "YES") {
        if (item.master_food_category_uuid == '12844a2db14f510f90333983f1fa07ad' || item.master_food_category_uuid == '1be5953589919b2b686f94e2b68dccea' || item.master_food_category_uuid == 'a16b3341e0203ea1e3435c5c04b177bd') {
          this.router.navigateByUrl(`product-combo-detail?id=` + item.master_menu_item_uuid);
        }
        else {
          // const modal = await this.modalController.create({
          // component: PleaseSelectPage,
          // cssClass: 'please-select-modal',
          // mode: 'md',
          // componentProps: {
          // data : {
          // item,
          // }
          // },
          // });
          // return await modal.present();
          this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
        }
        // this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
      else {
        this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
    }
  }

  getCartItems() {
    let cartDetail = this.store.getUserCart();
    this.cartItems = cartDetail;
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      //console.log(v);
      // Object.entries(v).forEach(([kk, vv]) => {
      // this.cartItemCount = +vv.quantity + +this.cartItemCount;
      // })
      this.cartItemCount = +v[0].total_quantity + +this.cartItemCount;
      this.totalCartAmount = +v[0].total + +this.totalCartAmount;
      // ------timer------------
      // clearInterval(this.time_left_counter);
      if (this.timer_start == true || this.cartItemCount == 0) {
        // this.time_left = timer_time;
        clearInterval(this.time_left_counter);
      }

      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            this.router.navigateByUrl('/started');
            return false;
          }
        }, setIntervalTimeOut);
      }
    })
  }

  getTotalQuantity(item) {
    return item[0].total_quantity;
  }

  checkOut() {
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
          // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              // clearInterval(this.time_left_counter);
              this.router.navigate([`payment-method`]);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false) //----------loade
        }
      );
  }

  removeItemFromCart(index) {
    let cart = this.store.getUserCart();
    cart.splice(index, 1);
    this.store.setUserCart(cart);
    this.ionViewWillEnter();
  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  /**
  * Function to add pull down feature 
  * @param event 
  */
  doRefresh(event) {
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.kiosk_apiService.by_uuid()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false)
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          event.target.complete();
          this.maintain_timer = setInterval(
            () => {
              if (this.shop.master_restaurant_status == 'M_ON') {
                this.is_maintaince_mode_on = true;
              }
              else {
                this.is_maintaince_mode_on = false;
              }
              this.is_maintaince_mode_on = false;
            }
            , telemetryTimeout);

        } else {
          event.target.complete();
        }
      }, err => {
        event.target.complete();
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
      });
  }

  getRestaurantDetails() {
  // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.kiosk_apiService.by_uuid()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          // this.getAppSettings();
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = true;
          }
          else {
            this.is_maintaince_mode_on = false;
          }
          // this.is_maintaince_mode_on =true;
        }
      }, error => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader.

      });
  }

  /**
  * Function to get app seting
  */

  getAppSettings() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.kiosk_apiService.kiosk_setting().subscribe(response => {

      // this.pageLoaderService.pageLoaderPersistent(false) //----------loader

      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        if (master_restaurant_uuid == '541959375d2572915a9a6c2ac0ac888') {
          this.app_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_maintanance_description_bs;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_ella_maintanance_description;
          this.app_store_closed_description2 = response.aaData.kiosk_settings.kiosk_setting_store_closed_description_bs;
        }
        else if (master_restaurant_uuid == 'ec7f31487d97c8de24dfe4c65bc8b7c5' || master_restaurant_uuid == '8c71fb3f7593543f2ad180d31148a7cf') {
          this.app_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_maintanance_description_scb;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_ella_maintanance_description_scb;

          this.app_store_closed_description2 = response.aaData.kiosk_settings.kiosk_setting_store_closed_description_scb;

        }
        else if (master_restaurant_uuid == '3b6e09d61abe12ab0b5066f51bbe14ac' || master_restaurant_uuid == '66877fd7fb789c16f381068d1bdee575') {
          this.app_maintanance_description2 = response.aaData.kiosk_settings.app_maintanance_description_jp;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.app_ella_maintanance_description_jp;
          this.app_store_closed_description2 = response.aaData.kiosk_settings.app_store_closed_description_jp;
        }

        else if (master_restaurant_uuid == 'b16c8ede916a2762ba8702263e541f45') {
          this.app_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_maintanance_description_sap ? response.aaData.kiosk_settings.kiosk_setting_maintanance_description_sap : environment.M_ON;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_ella_maintanance_description_sap ? response.aaData.kiosk_settings.kiosk_setting_ella_maintanance_description_sap : environment.ELLA_M_ON;
          this.app_store_closed_description2 = response.aaData.kiosk_settings.kiosk_setting_store_closed_description_sap ? response.aaData.kiosk_settings.kiosk_setting_store_closed_description_sap : environment.STORE_CLOSED;
        }
        else if (master_restaurant_uuid == '5a368ed3c82accfb20eefbd9f9722ae3') {
          this.app_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_maintanance_description_kjm_mob;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_ella_maintanance_description_kjm_mob;
        }
        else if (master_restaurant_uuid = 'd52fe358b93bbeab6dab2658c183d637'){
          this.app_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_maintanance_description_nlb_mob;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.kiosk_setting_ella_maintanance_description_nlb_mob;
        }
        else {
          this.app_maintanance_description2 = response.aaData.kiosk_settings.app_maintanance_description;
          this.app_ella_maintanance_description2 = response.aaData.kiosk_settings.app_ella_maintanance_description;
          this.app_store_closed_description2 = response.aaData.kiosk_settings.app_store_closed_description;
        }

      }
    }, err => {
      // this.pageLoaderService.pageLoaderPersistent(false) //----------loader

    });
  }

 


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }









  // async timeout_error() {
  // let telemetry_title = " 決裁が完了できませんでした";
  // let telemetry_message = "係員にお問い合わせください。";
  // this.translate.get('ERRORS').subscribe((data: any) => {
  // // telemetry_title = data.Unable_to_connect;
  // // telemetry_message = data.Please_contact_the_staff;
  // });

  // const alert = await this.alertController.create({
  // cssClass: 'my-custom-class',
  // mode: 'md',
  // header: telemetry_title,
  // message: "<p> 係員にお問い合わせください。 <br /> 係員不在の場合は、 <br /> 機器左側にある連絡先までご連絡ください。 </p>",
  // buttons: [

  // // {
  // // text: '',
  // // cssClass: 'cross',
  // // role: 'cancel',

  // // }
  // ]
  // });
  // setTimeout(() => { this.alertController.dismiss(); }, modalTimeout);
  // await alert.present();
  // }

  async incomplete_payment_timeOut(paymentAmount) {
    let telemetry_title = " 決済が正常に完了しませんでした。";
    let telemetry_message = "";
    this.translate.get('ERRORS').subscribe((data: any) => {
      // telemetry_title = data.Unable_to_connect;
      // telemetry_message = data.Please_contact_the_staff;
    });

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: telemetry_title,
      message: '<p>交通系支払金額</p><span class="black">' + this.currency + ' ' + paymentAmount + '</span><p>係員にお問い合わせください。係員不在の場合は、機器左側にある連絡先までご連絡ください</p></span>',
      // message: "'<p>交通系支払金額</p>'<span class="black">'+this.currency+' '+paymentAmount+'</span><p> '+insufficient_message_balance+'</p><span class="red">'+this.currency+' '+balanceAvailable+'</span>',",
      buttons: [

        // {
        // text: '',
        // cssClass: 'cross',
        // role: 'cancel',

        // }
      ]
    });

    setTimeout(() => { this.alertController.dismiss(); }, modalTimeout);
    await alert.present();
  }

  async incomplete_payment() {
    // let telemetry_title = " もう一度、カードタッチして下さい";
    let telemetry_message = "";
    this.translate.get('ERRORS').subscribe((data: any) => {
      // telemetry_title = data.Unable_to_connect;
      // telemetry_message = data.Please_contact_the_staff;
    });

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      // header: telemetry_title,
      message: "<p>もう一度、カードタッチして下さい </p>",
      buttons: [

        // {
        // text: '',
        // cssClass: 'cross',
        // role: 'cancel',

        // }
      ]
    });

    setTimeout(() => { this.alertController.dismiss(); }, modalTimeout);
    await alert.present();
  }

  ionViewDidLeave() {
    // clearInterval(this.telemetry_poller);
    // clearInterval(this.time_left_counter);
    // clearInterval(this.maintain_timer);
    // clearInterval( this.time_left_redirection_counter);
    this.obj_is_restaurant_closed.unsubscribe();
    this.obj_restaurantDetails.unsubscribe();
  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }

  async alertmsg_hardwareError() {
    let telemetry_title = "お取り扱いできません。";
    let telemetry_message = "係員にお問い合わせください。係員不在の場合は、機器左側にある連絡先までご連絡ください。";
    // this.translate.get('ERRORS').subscribe((data: any) => {
    // telemetry_title = data.Unable_to_connect;
    // telemetry_message = data.Please_contact_the_staff;
    // });

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: telemetry_title,
      message: '<p> 係員にお問い合わせください。</p><span><p>係員不在の場合は、機器左側にある連絡先までご連絡ください。 </p></span>',
      buttons: [
        // {
        // text: '<p>係員にお問い合わせください。係員不在の場合は 機器左側にある連絡先までご連絡ください</p>', //'Back',
        // role: 'cancel',
        // // handler: () => {
        // // this.alertController.dismiss();
        // // this.router.navigateByUrl('/home');

        // // }
        // }, 
        // {
        // text: '',
        // cssClass: 'cross',
        // role: 'cancel',
        // // handler: () => {
        // // this.alertController.dismiss();
        // // this.router.navigateByUrl('/home');
        // // }
        // }
      ]
    });

    setTimeout(() => { this.alertController.dismiss(); }, modalTimeout);
    await alert.present();
  }

 
 
  async openSheetModal(item) {

    const modal = await this.modalController.create({
      component: CartItemDetailPage,
      cssClass: 'app-cart-item-detail1',
      mode: 'md',
      componentProps: {
        data: item
      },
    });
    setTimeout(() => { modal.dismiss(); }, modalTimeout);
    return await modal.present();
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;

      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        // alert("helo");
        window.location.reload();
      }
    });
  }

  async error_modal() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: "Payment Failed",
      message: "Please Tab Again",
      buttons: [

        {
          text: 'Done',
          cssClass: '',
          role: 'cancel',

        }
      ]
    });
    setTimeout(() => { this.alertController.dismiss(); }, modalTimeout);
    await alert.present();
  }



  is_item_ella(item) {
    if (item.master_menu_type == 'ELLA') {
      return true;
    }
    else {
      return false;
    }
  }


  is_category_coffee(obj) {
    var obj2 = obj.key;
    var index = "NO";
    obj2 = obj2.split("__");
    if (obj2[1] !== undefined && obj2[1] !== null) {
      obj2 = obj2[1];
    }
    obj2 = obj2.toLowerCase();
    if (obj2 == "coffee" || obj2 == "tea" || obj2 == "others") {
      index = "YES";
    }
    // console.log(" CAT -- ", obj," INDEX ",index,"OBJ2 ",obj2);

    return index;
  }

  isItemCartAvaliable() {
    this.kiosk_apiService.cart_available().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.nameModal()
        }
        else {
          this.soldItemModal(res)
        }
      }
    )

  }

  async soldItemModal(data) {
    let itemImage = data.message.item_image
    let message = data.message.error
    let html = `<div class="custom-head">
      <span><img src="`+ itemImage + `"></span>
      <h2>Sorry</h2>
      <p>`+ message + `</p>
      </div>`
    // let t = "https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/1645716158_20220224112238.jpg";
    // let r = '<a><img src="'+t+'"></a>';
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal',
      mode: 'md',
      // header: data.message.error,
      message: html,
      // message: r,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.alertController.dismiss();
          }
        }
      ]
    });
    setTimeout(() => {this.alertController.dismiss();}, 5000);
    await alert.present();
    // const { role } = await alert.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role)
  }

  async technical_error(message?) {
    let errorMessage = '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>';
    if (message) {
      errorMessage = message;
    }
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: errorMessage,
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/home'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

// ==========payment========
async nameModal() {
  const modal = await this.modalController.create({
    component: NameModalPage,
    cssClass: 'my-custom-class',
    backdropDismiss: false
  });
  modal.onDidDismiss().then((modelData) => {

    if (modelData.data != 'dismiss') {

      this.loadAllData()
      this.create_kiosk_order()

    }

  });
  return await modal.present();
}

loadAllData() {

  const orderId = this.store.get('orderId')
  const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
  // if (items.length == 0 && !orderId) {
  //   this.router.navigate([`home`]);
  //   return false;
  // }
  let cartDetail = this.store.getUserCart();
  this.totalAmount = 0;
  Object.entries(cartDetail).forEach(([k, v]) => {
    this.totalAmount = v['total'] + +this.totalAmount;
    Object.entries(v).forEach(([key, value]) => {
      this.cartItems_create_order.push(value);
    })
  });
}

app_setting() {
  this.kiosk_apiService.restuarant_setting()
    .subscribe(
      (res) => {
        this.app_settings = res.aaData.module_permissions
      },
      error => {
      }
    );
}

create_kiosk_order() {
  let cartDetail = this.store.getUserCart();
  this.totalAmount = 0;
  this.cartItems_create_order = [];
  Object.entries(cartDetail).forEach(([k, v]) => {
    Object.entries(v).forEach(([key, value]) => {
      if (v['total']) {
        this.totalAmount = v['total'] + +this.totalAmount;
      }
      this.cartItems_create_order.push(value);
    })
  });
  let name = JSON.parse(window.localStorage.getItem('myObject'));
  let user_info = JSON.parse(window.localStorage.getItem('user_details'));

  let order_type = "TAKE_AWAY";
  if (this.store.get("order_type") == "Dine In") {
    order_type = "DINE_IN";
  }
  let totalCartAmount = this.totalAmount;
  //let cartItemCount = this.store.get("cartCartItemCount")
  //let cart = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
  let user = this.store.getUser();
  this.attempted = this.totalAmount;
  this.ServiceID = this.generate_rand_num();
  let data = {
    order_info_uuid: this.store.get('orderId'),
    order_info_customer: user.user_information_uuid,
    master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
    order_info_total_price: totalCartAmount,
    order_info_total_order_quantity: 0,
    order_info_total_discount: 0,
    available_redeemed_amount: 0,
    points_redeemed_amount: 0,
    master_coupon_uuid: null,
    master_subscription_uuid: null,
    subscription_mapping_uuid: null,
    used_subscription_quantity: 0,
    order_info_gst: 0, //Number(this.gst.toFixed(2)),
    // order_info_service_tax [OPTIONAL]
    order_info_grand_total: totalCartAmount,
    order_info_delivery_date: "",
    master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
    orders: this.cartItems_create_order,
    order_info_table_number: null,
    order_info_car_plate_number: null,
    order_info_cutlery: 'FALSE',
    order_info_type: order_type,//this.store.get("order_type"),
    order_info_dinningmode: null,
    order_info_dinningtime: null,
    delivery_address: null,
    is_sap_order: "NO",
    user_name: name.user_name,
    country_uuid: this.store.getCountry().master_country_uuid,
    request_from: "NLB_MOBILE_KIOSK",
    deducted: this.deducted,
    balance: this.balance,
    user_uuid: user_info && user_info.user_uuid ? user_info.user_uuid : "",
    customer_name: user_info && user_info.customer_name ? user_info.customer_name : "",
  }
  this.kiosk_apiService.create_kiosk_order(data).subscribe((res) => {
    if (res.statusCode == 200) {
      // this.thankyou();
      var testObject = {
        customer_name: res.aaData.user_information_fullname,
        user_uuid: res.aaData.user_information_uuid
      }

      this.store.set("user_details", testObject);
      // console.log("order create--------------------------->>>>>>>")
      this.thankyou();
      this.updateOrder(res, null, "QUEUED");
      this.store.set("orderId", res.aaData['order_info_uuid']);
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.store.setUserCart([]);
      this.store.remove("selectedItemsInCart");
      if (res.statusCode != 200) {
        if (res.aaData == false) {
          this.technical_error()
        }
      }
      if (res.aaData['master_restaurant_status'] == 'M_ON') {
        this.router.navigate([`home`]);
      } else {
      }
    } else {
    }
  }, err => {
    //   this.auditlogServices.logException("Order API exception", err, data);
  })
  //   })
}
order_UUID
isPaymentFailed

updateOrder(orderData, paymentResponse, isPaymentFailed) {
  this.cartItemCount = 0
  this.order_UUID = orderData.aaData.order_info_uuid
  this.isPaymentFailed = isPaymentFailed
  let posted_data = {
    data: {payment_type: "ELLA", used_quantity: 0, order_info_uuid:  this.order_UUID, deducted: 0, master_subscription_uuid: null, subscription_mapping_uuid: null}
    ,payment_type:"NA",card_type : "OTHERS"  
  }
    let data = {
      order_info_uuid: this.order_UUID,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: paymentResponse,
      payment_status: isPaymentFailed ? isPaymentFailed : '',
      suica_payment_details: posted_data,
    }

  this.auditlogServices.logUserPaymentLogs("Update order API params", data, null);
   this.kiosk_apiService.update_order(data).subscribe(res => {
    this.auditlogServices.logUserPaymentLogs("Update order API response", data, res);
    // if(res.statusCode == 200) { 
    this.store.remove("orderId");
    this.store.remove("totalAmount");
    this.store.remove("totalCartAmount");
    this.store.setCart([]);
    this.store.remove("selectedItemsInCart");
    //   this.router.navigate([`settlement-complete`]);
    if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {
      // this.alertController.dismiss()
      setTimeout(() => {  this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID + "&c=" + this.proc_card_idi + "&b=");;}, 3000);
    }

    else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
      // setTimeout(function () {
      //   window.location.reload();
      //   // this.thankyou().dismiss
      // }, 3000);

    }
    // return false;
    //} 
  }, err => {
    this.auditlogServices.logException("Order API exception", err, data);
  }
  );
}

async thankyou() {
  const alert = await this.alertController.create({
    cssClass: 'thankyou-alert',
    header: 'Thank You',
    subHeader: ' Enjoy Your Coffee!',
    message: '<a><img src="../../assets/image/thankYou.gif"></a>',
    mode: 'ios',
  });
  setTimeout(() => {this.alertController.dismiss();}, 3000);
  await alert.present();

  const { role } = await alert.onDidDismiss();
  // console.log('onDidDismiss resolved with role', role);
}

generate_rand_num() {
  var rand: any;
  rand = Math.random();
  rand = rand * 10000000000;
  rand = Math.floor(rand);
  rand = '' + rand;
  rand = rand.substr(0, 8);
  return rand;
}
}
