import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from '../services/localstore.service';
import { KioskauthService } from '../services/kioskauth.service';
import { ApiService } from '../services/api.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { AuditLogService } from '../services/audit-log.service';
import { KIOSK_AUTO_LOGIN } from '../common/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-name-modal',
  templateUrl: './name-modal.page.html',
  styleUrls: ['./name-modal.page.scss'],
})

export class NameModalPage implements OnInit {
  key = 'Name';
  username;
  counter;
  terminalid = "1";
  telemetry_poller: any;
  balance_poller: any;
  payment_progress = "";
  payment_terminal_status = "";
  payment_terminal_status_txt = "";
  payment_progress_txt = "";
  refund_progress_txt = "";
  modal_box = false;
  modal_type = "OK";
  payment_error: any;
  cartItems_create_order: any = [];
  totalAmount: number;
  currency = "¥";
  showDecimal = true;
  interval: any;
  timeOutApiSession = false;
  balance_blocker = false;
  telemetry_blocker = false;
  balance_shown = "";
  show_balance = false;
  balance_sub: any;
  ordering_avail = true;
  inavail_mode = "REPLENISH";
  software_version = "";
  forced_transmission = "";
  time_check = "OK";
  firmware_updating = false;
  check_nega = false;
  wait_card = false;
  payment_initiated = false;
  proc_card_idi;
  pay_sub: any;
  createorderRes;
  wait_for_paymnet;
  temp_balance: any;
  temp_total: any;
  _suica_alert: any;
  suica_alert_flag = 0;
  ServiceID: any;
  modal_time_count = 0;
  modal_timer;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  check = localStorage.getItem('Name')
  time_left_redirection_counter: any;
  attempted: any;
  deducted: any;
  balance: any;
  card_idi: any;
  ic_handling_number: any;
  order_UUID;
  isPaymentFailed;
  app_settings;
  message: any;
  pay_fail_popup;
  time_left_counter: any;
  time_left = 0;
  maintain_timer: any;
  error = '';

  constructor(
    public modalCtrl: ModalController,
    private route: ActivatedRoute,
    private store: LocalStoreService,
    private router: Router,
    private kioskauthservice: KioskauthService,
    public alertController: AlertController,
    private api: ApiService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    public modalController: ModalController,
    public auditlogServices: AuditLogService,
  ) { }

  ngOnInit() {
    let name = JSON.parse(window.localStorage.getItem('myObject'));
    this.username = name.user_name
  }

  closeModal() {
    this.modalCtrl.dismiss('dismiss');
  }


  checkout_orderCreate() {

    if (this.username.length > 12) {
      this.error = 'max_length_error';
      return false;
    } else {
      this.error = '';
      var myObject1 = {
        user_name: this.username,
      }
      this.store.set("myObject", myObject1);
      this.modalCtrl.dismiss(this.username);
    }
  }
}
